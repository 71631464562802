import {
	Row,
	Col,
	Card,
	Button,
	Dropdown,
	Table,
	Badge,
} from "react-bootstrap";
import { url } from "../services/axios";
import { RiDoorLockBoxLine, RiDoorLockFill } from "react-icons/ri";
import { LuHotel } from "react-icons/lu";
import { GiDoorHandle } from "react-icons/gi"

import Layout from "../layout/default";
import {
	Image,
	Icon,
	Media,
	MediaGroup,
	MediaText,
	Pureknob,
	CustomDropdownMenu,
	CustomDropdownToggle,
	OverlineTitle,
	ChartLegend,
} from "../components";
import { ChartBar, ChartLine } from "../components/Chart/Charts";
import { Colors } from "../utilities/index";
import hexRGB from "../utilities/hexRGB";
import { forwardRef, useEffect, useRef, useState } from "react";
import axios from "../services/axios";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { AiOutlineMenu } from "react-icons/ai";
import { BiSolidChevronDown } from "react-icons/bi";
import { MdEventAvailable } from "react-icons/md";
import { IoIosInformationCircle } from "react-icons/io";
import moment from "moment";
import "./css/Home.css";
import Home from "./Home";
import { getCookie } from "../services/cookieHandling";

// visitor chart data
let visitorsChartData = {
	labels: ["M", "T", "W", "T", "F", "S", "S"],
	yAxis: false,
	xGridColor: Colors.white,
	xGridBorderColor: Colors.white,
	datasets: [
		{
			label: "Visitors",
			borderColor: "transparent",
			backgroundColor: hexRGB(Colors.info, 0.3),
			hoverBackgroundColor: Colors.info,
			borderWidth: 1,
			borderRadius: 10,
			borderSkipped: false,
			data: [600, 680, 470, 770, 570, 810, 670],
		},
	],
};

// activity Chart
let activityChart = {
	labels: [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	],
	xAxis: false,
	yAxis: false,
	datasets: [
		{
			tension: 0.4,
			label: "Activity",
			borderColor: Colors.success,
			pointBackgroundColor: Colors.success,
			backgroundColor: hexRGB(Colors.success, 0.2),
			borderWidth: 2,
			pointBorderColor: "transparent",
			pointHoverBackgroundColor: Colors.success,
			fill: true,
			data: [120, 160, 95, 105, 98, 99, 167, 140, 155, 267, 237, 250],
		},
	],
};

// total Profit Chart
let totalProfitChart = {
	labels: [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	],
	stacked: true,
	ticksValue: "k",
	borderDash: [8, 4],
	xGridColor: Colors.white,
	xGridBorderColor: Colors.white,
	yGridBorderColor: Colors.white,
	maxTicksLimit: 20,
	datasets: [
		{
			borderRadius: {
				topLeft: 50,
				topRight: 50,
				bottomLeft: 50,
				bottomRight: 50,
			},
			backgroundColor: Colors.primary,
			label: "Total Income",
			borderSkipped: false,
			data: [120, 160, 95, 105, 98, 99, 167, 140, 155, 267, 237, 250],
		},
		{
			borderRadius: {
				topLeft: 50,
				topRight: 50,
				bottomLeft: 50,
				bottomRight: 50,
			},
			backgroundColor: Colors.success,
			label: "Total Profit",
			borderSkipped: false,
			data: [110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95],
		},
		{
			borderRadius: {
				topLeft: 50,
				topRight: 50,
				bottomLeft: 50,
				bottomRight: 50,
			},
			backgroundColor: Colors.gray300,
			label: "Total Expense",
			borderSkipped: false,
			data: [75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125],
		},
	],
};

// total sales knob chart
let totalSales = {
	size: 120,
	value: 65,
	angleOffset: -0.5,
	angleStart: 0.5,
	angleEnd: 0.5,
	colorFg: Colors.info,
};

// total revenue Chart
let totalRevenueChart = {
	labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
	yAxis: false,
	xAxis: false,
	datasets: [
		{
			tension: 0.4,
			label: "Total",
			borderColor: Colors.primary,
			backgroundColor: "transparent",
			borderWidth: 4,
			pointBorderColor: "transparent",
			pointBackgroundColor: "transparent",
			pointHoverBackgroundColor: Colors.primary,
			borderCapStyle: "round",
			data: [12, 40, 13, 130, 70, 210],
		},
	],
};

// sales analytics Chart
let salesAnalyticsChart = {
	labels: [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	],
	borderDash: [8, 4],
	maxTicksLimit: 10,
	ticksValue: "k",
	xAxis: false,
	xGridBorderColor: Colors.white,
	yGridBorderColor: Colors.white,
	datasets: [
		{
			tension: 0.4,
			borderWidth: 3,
			borderColor: Colors.yellow,
			backgroundColor: hexRGB(Colors.yellow, 0.2),
			pointBorderColor: "transparent",
			pointBackgroundColor: "transparent",
			pointHoverBackgroundColor: Colors.yellow,
			label: "Total Sales",
			fill: true,
			data: [40, 60, 30, 65, 60, 95, 90, 100, 96, 120, 105, 134],
		},
		{
			tension: 0.4,
			borderWidth: 2,
			pointBorderColor: "transparent",
			pointBackgroundColor: "transparent",
			borderColor: Colors.danger,
			pointHoverBackgroundColor: Colors.danger,
			label: "Total Bookings",
			borderDash: [8, 4],
			data: [70, 44, 49, 78, 39, 49, 39, 38, 59, 80, 56, 101],
		},
	],
};

function HomeEcommerce() {
	const [dashboardData, stDashboardData] = useState(null);
	const [mapData, setMapData] = useState(null);
	const [areaChat, setAreaChat] = useState(null);
	const [commissions, setCommissions] = useState([]);
	const [totalBalance, setTotalBalance] = useState(0);
	const [coriander, setCoriander] = useState(0);
	const [totalOnlineRooms, setTotalOnlineRooms] = useState(0);
	const [totalOfflineRooms, setTotalOfflineRooms] = useState(0);
	const [bookedOnlineRooms, setBookedOnlineRooms] = useState(0);
	const [bookedOfflineRooms, setBookedOfflineRooms] = useState(0);
	const[totalHotels, setTotalHotels]=useState([]);
	const [checkinDate, setCheckinDate] = useState(null);
	const [checkoutDate, setCheckoutDate] = useState(undefined);
	const [bookingDate, setBookingDate] = useState(undefined);
	const [resetDashboard, setResetDashboard] = useState(0);
	const [isFiltersOpen, setIsFiltersOpen] = useState(false);
	const [onlineBookingsCount, setOnlineBookingsCount] = useState(0);
	const [offlineBookingsCount, setOfflineBookingsCount] = useState(0);

	// enum: ["total", "present"]
	const [revenueTab, setRevenueTab] = useState("total");

	const token = localStorage.getItem("token");
	const role = localStorage.getItem("role");
	const hotelidentifier = localStorage.getItem("hotelidentifier");

	const today = new Date();

	today.setHours(0, 0, 0, 0);

	const getMapData = async () => {
		const { data, status } = await axios.get("Dashboard/graph?year=2023");
		if (status === 200 || status === 201) {
			setMapData({
				labels: data?.data?.label,
				stacked: true,
				ticksValue: "k",
				borderDash: [8, 4],
				xGridColor: Colors.white,
				xGridBorderColor: Colors.white,
				yGridBorderColor: Colors.white,
				maxTicksLimit: 20,
				datasets: [
					{
						borderRadius: {
							topLeft: 50,
							topRight: 50,
							bottomLeft: 50,
							bottomRight: 50,
						},
						backgroundColor: Colors.primary,
						label: "Online",
						borderSkipped: false,
						data: data?.data?.online,
					},
					{
						borderRadius: {
							topLeft: 50,
							topRight: 50,
							bottomLeft: 50,
							bottomRight: 50,
						},
						backgroundColor: Colors.primary,
						label: "Offline",
						borderSkipped: false,
						data: data?.data?.offline,
					},
				],
			});

			setAreaChat({
				labels: data?.data?.label,
				borderDash: [8, 4],
				maxTicksLimit: 10,
				ticksValue: "k",
				xAxis: false,
				xGridBorderColor: Colors.white,
				yGridBorderColor: Colors.white,
				datasets: [
					{
						tension: 0.4,
						borderWidth: 3,
						borderColor: Colors.yellow,
						backgroundColor: hexRGB(Colors.yellow, 0.2),
						pointBorderColor: "transparent",
						pointBackgroundColor: "transparent",
						pointHoverBackgroundColor: Colors.yellow,
						label: "Online",
						fill: true,
						data: data?.data?.online,
					},
					{
						tension: 0.4,
						borderWidth: 2,
						pointBorderColor: "transparent",
						pointBackgroundColor: "transparent",
						borderColor: Colors.danger,
						pointHoverBackgroundColor: Colors.danger,
						label: "Offline",
						borderDash: [8, 4],
						data: data?.data?.offline,
					},
				],
			});
		}
	};

	const getAllHotels = async () => {
		const { data, status } = await axios.get(`Hotel/list${getCookie("role") == "Hotel" ? '?author_id=' + getCookie("author_id") : "" }`)
	
		if (status === 200 || status === 201) {
			setTotalHotels(data.results)
		}
	}

	const fetchDashboard = async () => {
		const { data, status } = await axios.post(
			`${url}/Dashboard/fetch-dashboard`,
			{
				token,
				hotelidentifier,
				date: checkinDate ? checkinDate : undefined,
			}
		);

		if (data.success) {
			setBookedOnlineRooms(data.bookedOnlineRooms);
			setTotalOnlineRooms(data.totalOnlineRooms);
			setBookedOfflineRooms(data.bookedOfflineRooms);
			setTotalOfflineRooms(data.totalOfflineRooms);
		}
	};

	const fetchBookingsCount = async () => {
		const { data, status } = await axios.post(
			`${url}/Dashboard/bookings-count`,
			{
				token,
				hotelidentifier,
				date: checkinDate ? checkinDate : undefined,
			}
		);

		if (data.success) {
			setOnlineBookingsCount(data.onlineBookings);
			setOfflineBookingsCount(data.offlineBookings);
		}
	};

	// const getDashboardByDate = async () => {
	// 	const { data, status } = await axios.post(`${url}/Dashboard/filter`, { token, checkinDate, bookingDate })

	// 	if (data.success) {
	// 		setBookedOnlineRooms(data.bookedOnlineRooms)
	// 		setTotalOnlineRooms(data.totalOnlineRooms)
	// 		setBookedOfflineRooms(data.bookedOfflineRooms)
	// 		setTotalOfflineRooms(data.totalOfflineRooms)
	// 	}
	// }

	const fetchAllCommissions = async () => {
		const { data, status } = await axios.post(
			`${url}/Booking/get-commissions`,
			{ token, hotelidentifier, __payathotel: true }
		);

		if (data.success) {
			setCommissions(data.commissions);
		} else {
			console.log(data.message);
		}
	};

	// Calculate total earnings from commissions
	useEffect(() => {
		if (commissions.length) {
			let sum = 0;
			let present = 0;

			commissions.forEach((item) => {
				const amount =
					role === "Superadmin" || role === "Subadmin"
						? Number(item.superadminamount)
						: Number(item.hotelowneramount);

				const created = new Date(item.bookingid.created_at);

				if (created.toDateString() === today.toDateString()) {
					present += amount;
				}

				sum += amount;
			});

			setTotalBalance(sum);
			setCoriander(present);
		}
	}, [commissions]);

	useEffect(() => {
		fetchDashboard();
		fetchBookingsCount();
		getMapData();
		fetchAllCommissions();
		getAllHotels();
	}, [resetDashboard]);

	useEffect(() => {
		if (checkinDate && String(checkinDate) !== "Invalid Date") {
			fetchDashboard();
			fetchBookingsCount();
		} else {
			if (String(checkinDate) === "Invalid Date") {
				setCheckinDate(null);
			}
			setResetDashboard((prev) => prev + 1);
		}
	}, [checkinDate]);

	return (
		<div className="_8ysb">
			<Layout title="Dashboard">
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "10px",
						marginTop: "0px",
						marginBottom: "20px",
					}}
				>
					<div
						onClick={() => setIsFiltersOpen((prev) => !prev)}
						style={{
							display: "flex",
							alignItems: "center",
							backgroundColor: "white",
							padding: "5px 10px",
							borderRadius: "8px",
							cursor: "pointer",
						}}
					>
						<AiOutlineMenu />
						<span style={{ marginLeft: "8px" }}>Filters</span>
						<div style={{ marginLeft: "auto" }}>
							<BiSolidChevronDown />
						</div>
					</div>
					{isFiltersOpen ? (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								gap: "10px",
								backgroundColor: "white",
								padding: "10px 15px",
								borderRadius: "8px",
							}}
						>
							<div style={{ width: "170px" }}>
								<span style={{ fontSize: "14px", color: "grey" }}>Date</span>
								<Form.Control
									type="date"
									onChange={(e) => setCheckinDate(new Date(e.target.value))}
								/>
							</div>
							<div
								onClick={() => {
									setCheckinDate(null);
								}}
								style={{
									marginLeft: "auto",
									backgroundColor: "rgb(119, 188, 119)",
									padding: "5px 30px",
									cursor: "pointer",
									borderRadius: "20px",
									fontSize: "14px",
									color: "white",
								}}
							>
								Reset
							</div>
						</div>
					) : undefined}
				</div>
				<Col sm="6" xl="4" xxl="4">
					<Card
						className="h-100"
						style={{
							marginRight: "20px",
							backgroundColor: "white",
							boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
						}}
					>
						<div
							className="app-dash-card"
							style={{ height: "200px", position: "relative" }}
						>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<h5>Revenue</h5>
								<div style={{ display: "flex", gap: "5px", fontSize: "10px" }}>
									<div
										onClick={() => setRevenueTab("total")}
										style={{
											display: "flex",
											alignItems: "center",
											fontFamily: "Roboto",
											color:
												revenueTab === "present" ? "rgb(252, 79, 0)" : "white",
											backgroundColor:
												revenueTab === "total" ? "rgb(252, 79, 0)" : "white",
											padding: "3px 10px",
											border: "2px solid rgb(252, 79, 0)",
											borderRadius: "3px",
											cursor: "pointer",
										}}
									>
										Overall
									</div>
									<div
										onClick={() => setRevenueTab("present")}
										style={{
											display: "flex",
											alignItems: "center",
											fontFamily: "Roboto",
											color:
												revenueTab === "total" ? "rgb(252, 79, 0)" : "white",
											backgroundColor:
												revenueTab === "present" ? "rgb(252, 79, 0)" : "white",
											padding: "3px 10px",
											border: "2px solid rgb(252, 79, 0)",
											borderRadius: "3px",
											cursor: "pointer",
										}}
									>
										Today
									</div>
								</div>
							</div>
							<h2
								style={{
									fontFamily: "Roboto",
									color: "rgb(101, 101, 101)",
									marginTop: "40px",
								}}
							>
								{revenueTab === "total"
									? totalBalance.toLocaleString("en-IN", {
										style: "currency",
										currency: "INR",
										minimumFractionDigits: 2,
									})
									: coriander.toLocaleString("en-IN", {
										style: "currency",
										currency: "INR",
										minimumFractionDigits: 2,
									})}
							</h2>
						</div>
					</Card>
				</Col>
				<div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
					<div
						style={{
							backgroundColor: "white",
							width: "370px",
							height: "200px",
							borderRadius: "8px",
							boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
							padding: "20px",
							userSelect: "none",
						}}
					>
						<div
							style={{
								fontSize: "16px",
								fontFamily: '"Manrope", sans-serif',
								fontWeight: "700",
								display: "flex",
								alignItems: "center",
								gap: "5px",
								color: "#2C2C2C",
							}}
						>
							<div
								style={{
									borderLeft: "5px solid rgb(252, 79, 0)",
									paddingLeft: "15px",
									borderRadius: "2px",
								}}
							>
								Rooms availability
							</div>
							<div style={{ fontSize: "10px", color: "orange" }}>
								(for{" "}
								{checkinDate ? new Date(checkinDate).toDateString() : "today"})
							</div>
						</div>
						<div style={{ display: "flex", gap: "20px" }}>
							<div
								style={{
									width: "120px",
									height: "120px",
									marginTop: "10px",
									backgroundColor: "rgb(255, 248, 235)",
									borderRadius: "8px",
									overflow: "hidden",
								}}
							>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											fontSize: "12px",
											color: "white",
											backgroundColor: "rgb(252, 79, 0)",
											width: "50px",
										}}
									>
										Online
									</div>
								</div>
								<div style={{ display: "flex", justifyContent: "center" }}>
									<div style={{ position: "relative" }}>
										<div
											style={{
												position: "absolute",
												top: "17px",
												right: "2px",
												fontSize: "32px",
												fontWeight: "700",
											}}
										>
											{totalOnlineRooms - bookedOnlineRooms}
										</div>
										<div
											style={{
												position: "absolute",
												top: "10px",
												fontSize: "40px",
												fontWeight: "700",
											}}
										>
											/
										</div>
										<div
											style={{
												position: "absolute",
												top: "30px",
												left: "14px",
												fontSize: "20px",
												fontWeight: "700",
											}}
										>
											{totalOnlineRooms}
										</div>
									</div>
								</div>
							</div>
							<div
								style={{
									width: "120px",
									height: "120px",
									marginTop: "10px",
									backgroundColor: "rgb(255, 248, 235)",
									borderRadius: "8px",
									overflow: "hidden",
								}}
							>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											fontSize: "12px",
											color: "white",
											backgroundColor: "rgb(252, 79, 0)",
											width: "50px",
										}}
									>
										Offline
									</div>
								</div>
								<div style={{ display: "flex", justifyContent: "center" }}>
									<div style={{ position: "relative" }}>
										<div
											style={{
												position: "absolute",
												top: "17px",
												right: "2px",
												fontSize: "32px",
												fontWeight: "700",
											}}
										>
											{totalOfflineRooms - bookedOfflineRooms}
										</div>
										<div
											style={{
												position: "absolute",
												top: "10px",
												fontSize: "40px",
												fontWeight: "700",
											}}
										>
											/
										</div>
										<div
											style={{
												position: "absolute",
												top: "30px",
												left: "14px",
												fontSize: "20px",
												fontWeight: "700",
											}}
										>
											{totalOfflineRooms}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						style={{
							backgroundColor: "white",
							width: "370px",
							height: "200px",
							borderRadius: "8px",
							boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
							padding: "20px",
							userSelect: "none",
						}}
					>
						<div
							style={{
								fontSize: "16px",
								fontFamily: '"Manrope", sans-serif',
								fontWeight: "700",
								display: "flex",
								alignItems: "center",
								gap: "5px",
								color: "#2C2C2C",
							}}
						>
							<div
								style={{
									borderLeft: "5px solid rgb(252, 79, 0)",
									paddingLeft: "15px",
									borderRadius: "2px",
								}}
							>
								Bookings
							</div>
							<div style={{ fontSize: "10px", color: "orange" }}>
								(made{" "}
								{checkinDate ? new Date(checkinDate).toDateString() : "today"})
							</div>
						</div>
						<div style={{ display: "flex", gap: "20px" }}>
							<div
								style={{
									width: "120px",
									height: "120px",
									marginTop: "10px",
									backgroundColor: "rgb(255, 248, 235)",
									borderRadius: "8px",
									overflow: "hidden",
								}}
							>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											fontSize: "12px",
											color: "white",
											backgroundColor: "rgb(252, 79, 0)",
											width: "50px",
										}}
									>
										Online
									</div>
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										marginTop: "16px",
									}}
								>
									<div style={{ fontSize: "32px", fontWeight: "700" }}>
										{onlineBookingsCount}
									</div>
								</div>
							</div>
							<div
								style={{
									width: "120px",
									height: "120px",
									marginTop: "10px",
									backgroundColor: "rgb(255, 248, 235)",
									borderRadius: "8px",
									overflow: "hidden",
								}}
							>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											fontSize: "12px",
											color: "white",
											backgroundColor: "rgb(252, 79, 0)",
											width: "50px",
										}}
									>
										Offline
									</div>
								</div>
								<div style={{ display: "flex", justifyContent: "center" }}>
									<div
										style={{
											fontSize: "32px",
											fontWeight: "700",
											marginTop: "16px",
										}}
									>
										{offlineBookingsCount}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="mishap-show">
					<div className="subfloor-hen">
						<div className="main-floor"
						>
							<LuHotel
								style={{
									fontSize: "20px",
									color: "rgba(252, 79, 0, 1)"
								}}
							/>
							Hotels
						</div>
						<div  className="main-shiuh">{totalHotels.length}</div>
					</div>
					<div className="subfloor-hen">
						<div className="main-floor"
						>
							<RiDoorLockBoxLine								
							style={{
									fontSize: "20px",
									color: "rgba(252, 79, 0, 1)"
								}}
							/>
							Rooms
						</div>
						<div  className="main-shiuh">{totalOnlineRooms + totalOfflineRooms}</div>
					</div>
				</div>
				<Row className="g-gs" style={{ marginTop: "4px" }}>
					<Col xxl="8">
						<Card className="h-100">
							<Row className="g-0 col-sep col-sep-md">
								<Col md="12">
									<Card.Body>
										<div className="card-title-group mb-4">
											<div className="card-title">
												<h4 className="title">Total Bookings</h4>
											</div>
										</div>
										<div className="nk-chart-ecommerce-total-profit">
											<ChartBar data={mapData ?? totalProfitChart} />
										</div>
									</Card.Body>
								</Col>
							</Row>
						</Card>
					</Col>
					<Col xxl="6">
						<Card className="h-100">
							<Card.Body>
								<div className="card-title-group">
									<div className="card-title">
										<h4 className="title">Sales Analytics</h4>
									</div>
									<div className="card-tools">
										<Dropdown>
											<Dropdown.Toggle
												size="sm"
												as={CustomDropdownToggle}
												className="btn btn-sm btn-icon btn-zoom me-n1"
											>
												<Icon name="more-v"></Icon>
											</Dropdown.Toggle>
											<Dropdown.Menu
												className="dropdown-menu-sm"
												as={CustomDropdownMenu}
												align="end"
											>
												<Dropdown.Header className="py-2">
													<h6 className="mb-0">Options</h6>
												</Dropdown.Header>
												<Dropdown.Divider className="mt-0" />
												<Dropdown.Item>7 Days</Dropdown.Item>
												<Dropdown.Item>15 Days</Dropdown.Item>
												<Dropdown.Item>30 Days</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div>
								</div>
								<div className="nk-chart-ecommerce-sales-analytics mt-3">
									<ChartLine data={areaChat ?? salesAnalyticsChart} />
								</div>
								<ChartLegend.Group className="justify-content-center gap gx-4 pt-3">
									<div className="gap-col">
										<ChartLegend symbol="warning">Total Online</ChartLegend>
									</div>
									<div className="gap-col">
										<ChartLegend symbol="danger">Total Offline</ChartLegend>
									</div>
								</ChartLegend.Group>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Layout>
		</div>
	);
}

export default HomeEcommerce;
