import { Button, Card, Modal } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import axios from '../../services/axios'
import Layout from '../../layout/default'
import Block from '../../components/Block/Block'
import DataTable from '../../components/DataTable/DataTable'
import { Icon, } from '../../components'
import { useEffect, useState } from 'react'
import { getCookie } from '../../services/cookieHandling'
import { toast } from 'react-toastify'
import { userPortalLink } from "../../services/constants"
import { Dropdown } from "react-bootstrap"
import { QRCodeCanvas } from 'qrcode.react'

import { CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem, Rating } from "../../components"
import { BiShareAlt } from "react-icons/bi"
import { PulseLoader } from 'react-spinners'


const RenderQR = ({ text }) => {
	return (
		<div className="_4lyx">
			<div className="tweezer-soot">
				<QRCodeCanvas
					id="_9iak-qrcode"
					value={text}
					size="200"
					fgColor="rgb(75, 75, 75)"
				/>
			</div>
		</div>
	)
}

function FeaturedHotels() {
	const location = useLocation()
	const [hotels, setHotels] = useState(null)
	const [deleteModal, setdeleteModal] = useState(false)
	const [roomId, setRoomId] = useState(null)
	const [qrcode, setQrcode] = useState(undefined)
	const [property, setProperty] = useState(undefined)
	const [loading, setLoading] = useState(false);

	const handleShowModal = () => setdeleteModal(true)
	const handleCloseModal = () => setdeleteModal(false)

	const token = localStorage.getItem("token")

	const fetchOptedForFeatured = async () => {
		setLoading(true)
		const { data, status } = await axios.post("/Hotel/featured-requests", { token })

		if (data.success) {
			setHotels(data.featured)
		}
		setLoading(false)
	}

	const handleToggleFeatured = async (identifier, featured) => {
		const { data, status } = await axios.post("/Hotel/toggle-featured", { token, identifier, featured })

		if (data.success) {
			toast.success(data.message)
			fetchOptedForFeatured()
		}
	}

	const deleteUser = async () => {
		const { data, status } = await axios.delete(`Hotel/delete/${roomId}`)
		if (status == 204) {
			toast.success(`Hotel deleted successfully`)
			fetchOptedForFeatured()
			setdeleteModal(false)
		}
	}

	const handleQRDownload = () => {
		const canvas = document.getElementById("_9iak-qrcode")
		const pngUrl = canvas
			.toDataURL("image/png")
			.replace("image/png", "image/octet-stream")
		let downloadLink = document.createElement("a")
		downloadLink.href = pngUrl
		downloadLink.download = property ? `${property.title} - booking.png` : "QR.png"
		document.body.appendChild(downloadLink)
		downloadLink.click()
		document.body.removeChild(downloadLink)
	}

	useEffect(() => {
		fetchOptedForFeatured()
	}, [])
	
	console.log({ hotels })

	const allColumns = [
		{
			name: "Title",
			grow: 3,
			selector: (row) => row.title,
			sortable: false,
		},
		{
			name: "Address",
			selector: (row) => row.real_address,
			cell: (row) => (
				<span>{row.real_address}</span>
			),

		},
		{
			name: "price",
			selector: (row) => row.price,
			cell: (row) => (
				<span className="small">{row.price}</span>
			),
			sortable: false,
		},
		{
			name: "rating",
			selector: (row) => row.rating_standard,
			cell: (row) => (
				<Rating rating={row.rating_standard} />
			),
			sortable: false,
		},
		{
			name: "status",
			selector: (row) => row.status,
			cell: (row) => (
				<span className={`badge text-bg-${row.featured ? "success-soft" : "info-soft"}`
				}>
					{row.featured ? "Featured" : "Pending"}
				</span>
			),
			sortable: false,
		},
		{
			name: "action",
			cell: (row) => (
				<div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }} className="text-end w-100">
					<div>
						<div>
							<button
								onClick={() => handleToggleFeatured(row._id, !row.featured)}
								style={{ backgroundColor: row.featured ? "grey" : "rgb(69, 133, 69)", color: "white", padding: "5px 10px", border: "none", borderRadius: "4px", cursor: "pointer", whiteSpace: "nowrap" }}
							>
								<span>{row.featured ? "Remove featured" : "Add to featured"}</span>
							</button>
						</div>
					</div>
					<Dropdown>
						<Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
							<div style={{ width: "100%", height: "100%" }} onClick={() => setProperty(row)}>
								<Icon name="more-v"></Icon>
							</div>
						</Dropdown.Toggle>
						<Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
							<div className="dropdown-content py-1">
								<LinkList className="link-list-hover-bg-primary link-list-md">
									<LinkListItem to={`/hotel-manage/add-hotel/${row._id}`}>
										<Icon name="edit"></Icon><span>Edit</span>
									</LinkListItem>
									<li>
										<Button className="btn-custom" onClick={() => { setRoomId(row._id); handleShowModal() }}>
											<Icon name="trash"></Icon><span>Delete</span>
										</Button>
									</li>
									<li>
										<Button
											onClick={() => setQrcode(`${userPortalLink}/properties-inner/${row._id}?locationId=${row.location_id._id}&locationTitle=${row.location_id.title}&from=null&to=null&adults=null&childrens=null&rooms=null`)}
											style={{ marginLeft: "3px" }}
											className="btn-custom"
										>
											<BiShareAlt /><span style={{ marginLeft: "10px" }}>Generate QR</span>
										</Button>
									</li>
								</LinkList>
							</div>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			),
			sortable: false,
		},
	]
	
	return (
		<Layout title="Hotels" content="container">
			{qrcode ? (
				<div onClick={() => setQrcode(undefined)} style={{ position: "fixed", top: "0px", left: "0px", width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 2000, backgroundColor: "rgba(128, 128, 128, 0.3)" }}>
					<div onClick={e => e.stopPropagation()} style={{ backgroundColor: "white", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "30px" }}>
						<RenderQR text={qrcode} />
						<div 
							onClick={() => handleQRDownload()}
							style={{ fontSize: "12px", color: "#ff6431", cursor: "pointer", marginTop: "10px" }}
						>Download</div>
					</div>
				</div>
			) : undefined}
			<Block.Head>
				<Block.HeadBetween>
					<Block.HeadContent>
						<Block.Title tag="h2">{location.pathname.includes("featured-hotels") ? "Featured" : "All"}  Hotels</Block.Title>
						<nav>
							<ol className="breadcrumb breadcrumb-arrow mb-0">
								<li className="breadcrumb-item"><Link to="/home">Home</Link></li>
								<li className="breadcrumb-item">Hotels</li>
								<li className="breadcrumb-item active" aria-current="page">All Hotels</li>
							</ol>
						</nav>
					</Block.HeadContent>
					<Block.HeadContent>
						<ul className="d-flex">
							<li>
								<Link to="/hotel-manage/add-hotel" className="btn btn-primary btn-md d-md-none">
									<Icon name="plus" />
									<span>Add</span>
								</Link>
							</li>
							{getCookie("role") == "Superadmin" && <li>
								<Link to="/hotel-manage/add-hotel" className="btn btn-primary d-none d-md-inline-flex">
									<Icon name="plus" />
									<span>Add Hotel</span>
								</Link>
							</li>}
						</ul>
					</Block.HeadContent>
				</Block.HeadBetween>
			</Block.Head>
			<Block>
				<Card>
					{loading ? (
						<div
						style={{
						minHeight: "500px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						}}
						>
						<PulseLoader color="grey" />
						</div>
					) : (
						hotels && 
						<DataTable
						tableClassName="data-table-head-light table-responsive data-table-checkbox"
						data={hotels} 
						originalTable={hotels}
						columns={allColumns} 
						searchTerm={'Title'}
						selectableRows={false} ></DataTable>
					)}
				</Card>
			</Block>
			<Modal show={deleteModal} onHide={handleCloseModal}>
				<div className="modal-content">
					<div className="modal-body p-5 text-center">
						<div className="media media-rounded media-xxl media-middle mx-auto text-bg-danger mb-4"><em className="icon ni ni-cross"></em></div>
						<h3>Are You Sure ?</h3>
						<p className="small">This hotel will be removed permanently.</p>
						<ul className="d-flex gap g-3 justify-content-center pt-1">
							<li><Button variant="success" onClick={() => { deleteUser() }}>Yes Delete it!</Button></li>
							<li><Button variant="danger" className="btn-soft" onClick={handleCloseModal}>Cancel</Button></li>
						</ul>
					</div>
					<button type="button" className="btn-close position-absolute top-0 end-0 p-3" onClick={handleCloseModal} aria-label="Close"></button>
				</div>
			</Modal>
		</Layout>
	)
}

export default FeaturedHotels
