import { Link } from "react-router-dom"
import { Dropdown } from "react-bootstrap"

import {
	MediaGroup,
	Media,
	MediaText,
	Image,
	Icon,
	CustomDropdownToggle,
	CustomDropdownMenu,
	LinkList,
	LinkListItem,
} from "../../components"
import { toInitials } from "../../utilities"
import moment from "moment"
import { toast } from "react-toastify"
import axios from "../../services/axios"

const generateInvoice = async (encoded) => {
	const linkSource = `data:application/pdf;base64,${encoded}`
	const downloadLink = document.createElement("a")
	const fileName = "file.pdf"

	downloadLink.href = linkSource
	downloadLink.download = fileName
	downloadLink.click()
}

const toggleCheckout = async (identifier, setTriggerRefresh) => {
	const token = localStorage.getItem("token")

	const { data, status } = await axios.post("/Booking/set-checkout", { token, identifier })

	if (data.success) {
		toast.success(data.message)
		setTriggerRefresh(prev => prev + 1)
	}
}

// user table column
export const bookingColumns = (setTriggerRefresh) => [
	{
		name: "Booking id",
		selector: (row) => row.booking_id,
		cell: (row) => {
			const hotelinformation = row.hotel_infomration ? JSON.parse(row.hotel_infomration) : undefined
			
			const [hotelprefix, hotelsuffix] = hotelinformation[0].hotel_id.title.split(" ")

			const hotelinitials = hotelsuffix ? hotelprefix[0].toUpperCase() + hotelsuffix[0].toUpperCase() : hotelprefix[0].toUpperCase()

			const today = new Date()

			const bookingid = hotelinitials + (today.getMonth() + 1).toString().padStart(2, '0') + today.getFullYear().toString().slice(-2) + String(row.rowid).padStart(5, "0")

			return <span>{bookingid}</span>
		},
	},
	{
	    name: "Hotel Title",
	    selector: (row) => JSON.parse(row.hotel_infomration)[0].hotel_id.title,
	    cell: (row) => (
	        <span>{JSON.parse(row.hotel_infomration)[0].hotel_id.title}</span>
	    ),
	},
	{
		name: "CUSTOMER NAME",
		selector: (row) => row.author_name,
		cell: (row) => {
			
			return <span>{row.author_name}</span>
		},
	},
	
	// {
	// 	name: "Hotel Name",
	// 	selector: (row) => row.,
	// 	cell: (row) => {
	// 		console.log({row})
	// 		return <span>{row.hotel_id.title}</span>
	// 	},
	//
	// },
	{
		name: "Number of Rooms",
		selector: (row) => row.no_of_rooms,
		cell: (row) => {
			console.log({row})
			return <span>{row.no_of_rooms}</span>
		},
		sortable: false,
		hide: "lg",
	},
	{
		name: "Checkin data",
		selector: (row) => row.date,
		cell: (row) => (
			<span> {moment(row.checkin_date).format("DD/MM/YYYY HH:MM")}</span>
		),
		sortable: false,
		hide: "lg",
	},
	{
		name: "Checkout data",
		selector: (row) => row.checkout_date,
		cell: (row) => (
			<span> {moment(row.checkout_date).format("DD/MM/YYYY HH:MM")}</span>
		),
		sortable: false,
		hide: "lg",
	},
	{
		name: "Checkout Status",
		selector: (row) => row.checkedout ? row.checkedout.value : undefined,
		cell: (row) => (
			row.checkedout && row.checkedout.value ? <span style={{ textWrap: "nowrap", backgroundColor: "rgb(141, 217, 141)", padding: "4px 8px" }}>Checked out</span> : undefined
		),
		sortable: false,
		// hide: "lg",
	},
	{
		name: "Booked By",
		selector: (row) => row.booked_by ? row.booked_by.first_name + " " + row.booked_by.last_name : "",
		cell: (row) => (
			row.booked_by ? <div>
				<span style={{ textWrap: "nowrap" }}>{row.booked_by.last_name ? row.booked_by.first_name + " " + row.booked_by.last_name : row.booked_by.first_name}</span>
				{row.booked_by.role ? <span style={{ marginLeft: "8px", backgroundColor: "grey", color: "white", borderRadius: "4px", padding: "4px 6px", fontSize: "12px", textWrap: "nowrap" }}>{row.booked_by.role}</span> : undefined}
			</div> : undefined
		),
		sortable: false,
		hide: "lg",
	},
	{
		name: "Number of Adults",
		selector: (row) => row.no_of_adults,
		cell: (row) => <span>{row.no_of_adults}</span>,
		sortable: false,
		hide: "lg",
	},
	{
		name: "Number of Childrens",
		selector: (row) => row.no_of_chlidrens,
		cell: (row) => <span>{row.no_of_chlidrens}</span>,
		sortable: false,
		hide: "lg",
	},
	{
		name: "Paid",
		selector: (row) => row.total_paid,
		cell: (row) => <span> ₹{row.total_paid}</span>,
		sortable: false,
		hide: "lg",
	},
	{
		name: "status",
		selector: (row) => row.booking_status,
		cell: (row) => (
			<span
				className={`badge text-bg-${row.booking_status === "Confirmed"
					? "success-soft"
					: row.booking_status === "Refunded"
						? "warning-soft"
						: row.booking_status === "Failed"
							? "secondary-soft"
							: row.booking_status === "Pending"
								? "warning-soft"
								: row.booking_status === "Processing"
									? "warning-soft"
									: row.booking_status === "Cancelled"
										? "secondary-soft"
										: "primary-soft"
					}`}
			>
				{row.booking_status ? row.booking_status : ""}
			</span>
		),
		sortable: false,
	},
	{
		name: "Payment status",
		selector: (row) => row.payment_status,
		cell: (row) => (
			<span
				className={`badge text-bg-${row.payment_status === "Success"
					? "success-soft"
					: row.payment_status === "Processing"
						? "warning-soft"
						: row.payment_status === "Failed"
							? "secondary-soft"
							: "primary-soft"
					}`}
			>
				{row.payment_status ? row.payment_status : ""}
			</span>
		),
		sortable: false,
	},
	{
		name: <div>Actions</div>,
		selector: (row) => row.action,
		cell: (row) => {
			return (
				<div style={{ display: "flex", width: "100%" }}>
					<Dropdown style={{ marginLeft: "auto" }}>
						<Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
							<Icon name="more-v"></Icon>
						</Dropdown.Toggle>
						<Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
							<div style={{ display: "flex", flexDirection: "column", gap: "6px", padding: "10px" }}>
								<button onClick={() => row.invoice ? generateInvoice(row.invoice) : toast.error("Invoice unavailable")} style={{ borderRadius: "4px", border: "0px", width: "150px", height: "40px", backgroundColor: "rgb(245, 245, 245)", display: "flex", alignItems: "center", paddingLeft: "10px" }}>Generate invoice</button>
								{row.checkedout && row.checkedout.value ? undefined : <button style={{ border: "0px", borderRadius: "4px", width: "150px", height: "40px", backgroundColor: "rgb(245, 245, 245)", display: "flex", alignItems: "center", paddingLeft: "10px" }} onClick={() => toggleCheckout(row._id, setTriggerRefresh)}>Mark as checked-out</button>}
							</div>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			)
		},
		sortable: false,
		hide: "lg",
	},
	// {
	//     name: "action",
	//     cell: (row) => (
	//         <div className="text-end w-100">
	//             <Dropdown>
	//                 <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
	//                     <Icon name="more-v"></Icon>
	//                 </Dropdown.Toggle>
	//                 <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
	//                     <div className="dropdown-content py-1">
	//                         <LinkList className="link-list-hover-bg-primary link-list-md">
	//                             <LinkListItem to={`/hotel-manage/room-edit`}>
	//                                 <Icon name="edit"></Icon><span>Edit</span>
	//                             </LinkListItem>
	//                         </LinkList>
	//                     </div>
	//                 </Dropdown.Menu>
	//             </Dropdown>
	//         </div>
	//     ),
	//     sortable: false,
	//
	// },
]

console.log(bookingColumns)

// users data
const bookings = [
	{
		hotel: "Property 1",
		room: "Standard room",
		date: "26-12-8899",
		execution: `Check in : 03/20/2023 Check out : 03/26/2023 Duration : 6 nights`,
		total: "1122",
		paid: "0",
		balance: "1122",
		remain: "1122",
		status: "Pending",
	},
]

export default bookings
