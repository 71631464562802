import { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Dropdown, Button, Offcanvas, Alert } from 'react-bootstrap'
import SimpleBar from 'simplebar-react'
import decode from "jwt-decode"
import { Logo, Image, Icon, MediaAction, MediaGroup, MediaText, Media, LinkList, LinkListItem, CustomDropdownToggle, CustomDropdownMenu, Schedule } from '../../../components'
import ToggleSidebar from '../Toggle/Sidebar'
import ToggleNavbar from '../Toggle/Navbar'
import { FaChevronDown } from "react-icons/fa"
import { useLayout, useLayoutUpdate } from './../LayoutProvider'
import { deleteAll } from '../../../services/cookieHandling'
import axios from "../../../services/axios"
import { AppContext } from "../../../router"
import { useNavigate } from "react-router"
import "./index.css"
import Background from "../../../assets/images/background_8.jpg"

function QuickNav({ className, ...props }) {
    const compClass = classNames({
        "nk-quick-nav": true,
        [className]: className,
    })
    return (
        <ul className={compClass}>{props.children}</ul>
    )
}

function QuickNavItem({ className, ...props }) {
    const compClass = classNames({
        "d-inline-flex": true,
        [className]: className,
    })
    return (
        <li className={compClass}>{props.children}</li>
    )
}

function Header() {
    const [showOffcanvas, setShowOffcanvas] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [unreadNotifications, setUnreadNotifications] = useState([])

    const { state, actions } = useContext(AppContext)

    const navigate = useNavigate()

    const selectedHotel = localStorage.getItem("selectedHotel")

    const layout = useLayout()
    const layoutUpdate = useLayoutUpdate()

    const compClass = classNames({
        "nk-header nk-header-fixed": true,
        [`is-${layout.headerVariant}`]: layout.headerVariant,
    })

    const navClass = classNames({
        "nk-header-menu nk-navbar": true,
        "navbar-active": layout.headerActive,
        // eslint-disable-next-line
        "navbar-mobile": layout.headerTransition || eval(`layout.breaks.${layout.headerCollapse}`) > window.innerWidth,
    })

    const handleOffcanvasShow = () => setShowOffcanvas(true)

    const token = localStorage.getItem("token")

    const decoded = decode(token)

    console.log({ decoded })

    const getNotifications = async () => {
        const { data, status } = await axios.post("/Notification/fetch-notification", { token })

        if (data.success) {
            setNotifications(data.notifications)
        }
    }

    const handleReadNotifications = async () => {
        unreadNotifications.map(async (item) => {
            await axios.post("/Notification/read-notification", { token, hash: item.hash })
        })
    }

    // Notifications canvas closed
    const handleOffcanvasClose = () => {
        getNotifications()
        setShowOffcanvas(false)
    }

    useEffect(() => {
        // Get notification on page load
        getNotifications()

        // Refresh notifications every five seconds
        let interval

        interval = setInterval(() => {
            getNotifications()
        }, 8000)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        setUnreadNotifications(notifications.filter(item => !item.read))
    }, [notifications])

    let __class
    let backgroundcolor = "white"
    let userImg = "/images/avatar/admin-icon.jpg"

    if (decoded && decoded.user.role === "Superadmin") {
        __class="_6nts"
        backgroundcolor = "white"
        userImg = "/images/avatar/admin-icon.jpg"
    }
    else if (decoded && decoded.user.role === "Hotel") {
        __class="_7tda"
        backgroundcolor = "red"
        userImg = "/images/avatar/hotel-owner.jpg"
    }
    else {
        backgroundcolor = "grey"
        userImg = "/images/avatar/user.jpg"
    }

    return (
        <>
            <div className={`_3ypt ${__class}`}>
                <div className="_6kaf container-fluid">
                    <div className="nk-header-wrap">
                        <div className="nk-header-logo">
                            <ToggleSidebar variant="zoom" icon='menu' />
                            <ToggleNavbar className="me-2" />
                            <Logo />
                        </div>
                        {layout.headerActive && <div className="navbar-overlay" onClick={layoutUpdate.headerMobile}></div>}
                        <nav className={navClass}>
                            {/* <Menu /> */}
                        </nav>
                        <div className="nk-header-tools">
                            <QuickNav>
                                <Dropdown as={QuickNavItem}>
                                    <Dropdown.Toggle style={{ color: decoded.user && decoded.user.role === "Superadmin" ? "whitesmoke" : "white" }} variant="zoom" size="sm" bsPrefix className="btn-icon d-sm-none">
                                        <Icon name="search"></Icon>
                                    </Dropdown.Toggle>
                                    <Dropdown.Toggle style={{ color: decoded.user && decoded.user.role === "Superadmin" ? "whitesmoke" : "white" }} variant="zoom" size="md" bsPrefix className="btn-icon d-none d-sm-inline-flex">
                                        <Icon name="search"></Icon>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-lg">
                                        <div className="dropdown-content dropdown-content-x-lg py-1">
                                            <div className="search-inline">
                                                <div className="form-control-wrap flex-grow-1">
                                                    <input placeholder="Search.." type="text" className="form-control-plaintext" />
                                                </div>
                                                <Icon name="search" size="sm"></Icon>
                                            </div>
                                        </div>
                                        <Dropdown.Divider className="m-0"></Dropdown.Divider>
                                        <div className="dropdown-content dropdown-content-x-lg py-3">
                                            <div className="dropdown-title pb-2">
                                                <h5 className="title">Recent searches</h5>
                                            </div>
                                            <ul className="dropdown-list gap gy-2">
                                                <li>
                                                    <MediaGroup>
                                                        <Media size="md" shape="circle" variant="light"><Icon name="clock"></Icon></Media>
                                                        <MediaText>
                                                            <div className="lead-text">Property X</div>
                                                            <span className="sub-text">1 days ago</span>
                                                        </MediaText>
                                                        <MediaAction end>
                                                            <Button size="md" variant="zoom" className="btn-icon me-n1"><Icon name="trash"></Icon></Button>
                                                        </MediaAction>
                                                    </MediaGroup>
                                                </li>
                                                <li>
                                                    <MediaGroup>
                                                        <Media size="md" shape="circle" variant="light"><Icon name="clock"></Icon></Media>
                                                        <MediaText>
                                                            <div className="lead-text">Property Y</div>
                                                            <span className="sub-text">07 Aug</span>
                                                        </MediaText>
                                                        <MediaAction end>
                                                            <Button size="md" variant="zoom" className="btn-icon me-n1"><Icon name="trash"></Icon></Button>
                                                        </MediaAction>
                                                    </MediaGroup>
                                                </li>
                                                <li>
                                                    <MediaGroup>
                                                        <Media size="md" shape="circle" variant="light"><Icon name="clock"></Icon></Media>
                                                        <MediaText>
                                                            <div className="lead-text">User Y</div>
                                                            <span className="sub-text">07 Dec</span>
                                                        </MediaText>
                                                        <MediaAction end>
                                                            <Button size="md" variant="zoom" className="btn-icon me-n1"><Icon name="trash"></Icon></Button>
                                                        </MediaAction>
                                                    </MediaGroup>
                                                </li>
                                            </ul>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <QuickNavItem>
                                    <button className="btn-icon btn btn-zoom btn-sm d-sm-none" onClick={handleOffcanvasShow}>
                                        <Icon name="bell"></Icon>
                                    </button>
                                    <div onClick={() => handleReadNotifications()} style={{ position: "relative" }}>
                                        <button style={{ color: decoded.user && decoded.user.role === "Superadmin" ? "whitesmoke" : "white", fontSize: "18px" }} className="btn-icon btn btn-zoom btn-md d-none d-sm-inline-flex" onClick={handleOffcanvasShow}>
                                            <Icon name="bell"></Icon>
                                        </button>
                                        {unreadNotifications.length ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "white", backgroundColor: "rgb(102, 209, 102)", width: "15px", height: "15px", fontSize: "10px", fontWeight: 600, position: "absolute", top: "0px", right: "0px", borderRadius: "50%" }}>
                                            {unreadNotifications.length}
                                        </div> : undefined}
                                    </div>
                                </QuickNavItem>
                                <Dropdown as={QuickNavItem}>
                                    <Dropdown.Toggle bsPrefix as={CustomDropdownToggle}>
                                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                            <div>
                                                <div className="d-inline-flex d-sm-none">
                                                    <Media shape="circle" size="md">
                                                        <Image src={userImg} staticImage thumbnail />
                                                    </Media>
                                                </div>
                                                <div className="d-none d-sm-flex">
                                                    <Media shape="circle">
                                                        <Image src={userImg} staticImage thumbnail />
                                                    </Media>
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ display: "flex", alignItems: "center", gap: "8px", color: decoded && decoded.user.role === "Superadmin" ? "whitesmoke" : "#640D6B", fontSize: "14px" }}>
                                                    <div style={{ fontWeight: "500", fontSize: "13px" }}>{decoded.user.first_name} {decoded.user.last_name}</div>
                                                </div>
                                                <div style={{ color: decoded && decoded.user.role === "Superadmin" ? "whitesmoke" : "#640D6B", fontSize: "12px", transform: "translateY(-2px)"  }}>
                                                    {decoded.user.email}
                                                </div>
                                                <div>
                                                {selectedHotel && <div style={{ fontSize: "10px", display: "flex", alignItems: "center", padding: "1px 6px", backgroundColor: "#B51B75", color: "whitesmoke", borderRadius: "3px", transform: "translate(0px, 0px)", width: "fit-content" }}>
                                                        {selectedHotel}
                                                    </div>}
                                                </div>
                                            </div>
                                            <div style={{ marginLeft: "12px", marginRight: "12px" }}>
                                                <FaChevronDown style={{ color: "#CA8787", width: "14px", height: "14px" }} />
                                            </div>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-md" as={CustomDropdownMenu}>
                                        <div className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                                            <MediaGroup>
                                                <MediaText>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                        <div className="lead-text">{decoded.user.first_name} {decoded.user.last_name}</div>
                                                        <div style={{ fontSize: "9px", padding: "1px 5px", color: "white", backgroundColor: "orange", borderRadius: "3px" }}>{decoded.user.role.toUpperCase()}</div>
                                                    </div>
                                                </MediaText>
                                            </MediaGroup>
                                        </div>
                                        {decoded.user.role === "Hotel" && <div style={{ cursor: "pointer" }} className="dropdown-content dropdown-content-x-lg py-2" onClick={() => {
                                            navigate("/select-hotel")
                                        }}>
                                            <div>
                                                <div>
                                                    <Icon name="signout"></Icon><span>Switch hotel</span>
                                                </div>
                                            </div>
                                        </div>}
                                        <div style={{ cursor: "pointer" }} className="dropdown-content dropdown-content-x-lg py-2" onClick={() => {
                                            actions.setTriggerRefresh(prev => prev + 1)
                                            deleteAll()
                                            navigate("/")
                                        }}>
                                            <div>
                                                <div>
                                                    <Icon name="signout"></Icon><span>Log Out</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </QuickNav>
                        </div>
                    </div>
                </div>
            </div>

            <Offcanvas className="offcanvas-size-lg" placement="end" show={showOffcanvas} onHide={handleOffcanvasClose}>
                <Offcanvas.Header closeButton className="border-bottom border-light">
                    <Offcanvas.Title>Recent Notification</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <SimpleBar>
                        <Schedule>
                            {notifications.map((item, index) => {
                                return (
                                    <Schedule.Item key={index} symbol={item.read ? "inactive" : "active"}>
                                        <span className="smaller">{new Date(item.createdAt).toDateString()}</span>
                                        <div className="h6">{item.header}</div>
                                        <div style={{ fontSize: "13px", color: "grey" }}>{item.text}</div>
                                    </Schedule.Item>
                                )
                            })}
                        </Schedule>
                    </SimpleBar>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Header