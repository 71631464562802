import { useEffect, useState } from 'react'
import { Card, Button, Modal, Form, Row, Col } from 'react-bootstrap'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import axios from '../../services/axios'
import Layout from '../../layout/default'
import Block from '../../components/Block/Block'
import DataTable from '../../components/DataTable/DataTable'
import { Icon, Select } from '../../components'
import UsersData, { userColumns } from '../../store/users/UsersData'
import { registerOwnerSchema } from '../../services/validations'
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify'
import { getCookie } from '../../services/cookieHandling'
import { Dropdown } from "react-bootstrap"
import { CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components"
import { MdBlock } from "react-icons/md"
import { PulseLoader } from 'react-spinners'

function HotelUsers() {
    const [deleteModal, setdeleteModal] = useState(false)
    const navigate = useNavigate()
    const handleShowModal = () => setdeleteModal(true)
    const handleCloseModal = () => setdeleteModal(false)
    const [roomId, setRoomId] = useState(null)
    const [users, setUsers] = useState(null)
    const [blockUserModal, setBlockUserModal] = useState(false)
    const [restrictUser, setRestrictUser] = useState(false)
    const [userInfo, setUserInfo] = useState(undefined)
    const [originalUsersList, setOriginalUsersList] = useState([])
    const [ownerHotels, setOwnerHotels] = useState([])
    const [loading, setLoading] = useState(false);

    const token = localStorage.getItem("token")
    const hotelidentifier = localStorage.getItem("hotelidentifier")

    const getAllHotel = async () => {
		const { data, status } = await axios.get(`Hotel/list${getCookie("role") == "Hotel" ? '?author_id=' + getCookie("author_id") : "" }`)

		if (status === 200 || status === 201) {
			setOwnerHotels(data.results.map(item => item._id))
		}
	}

    const fetchUsers = async () => {
        setLoading(true)
        const { data, status } = await axios.post("/Hotel/get-users", { token, hotelidentifier })

        if (data.success) {
            setUsers(data.users)
            setOriginalUsersList(data.users)
        }
        setLoading(false)
    }
    
    useEffect(() => {
        fetchUsers()
        getAllHotel()
    }, [])

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(registerOwnerSchema),
    })

    // const deleteUser = async () => {
    //     const { data, status } = await axios.delete(`Author/delete/${roomId}`)
    //     if (status == 204) {
    //         toast.success(`${getCookie("role") == "Superadmin" ? "Users" : "Staff"} deleted successfully`)
    //         fetchUsers()
    //         setdeleteModal(false)
    //     }
    // }

    const handleRestrictUser = async () => {
        const { data, status } = await axios.post("/Hotel/restrict-user", { token, identifier: userInfo._id, restrict: restrictUser })

        if (data.success) {
            fetchUsers()
            setBlockUserModal(false)
            toast.success(data.message)
        }
        else {
            toast.error(data.message)
        }
    }

    const ownerColumns = [
        {
            name: "Name",
            grow: 2,
            selector: (row) => (
                <div>
                    <span>{row.first_name}</span>
                    {row.blacklisted.some(r => ownerHotels.includes(r)) ? <span style={{ color: "white", marginLeft: "10px", backgroundColor: "red", padding: "2px 5px", borderRadius: "5px" }}>Blocked</span> : undefined}
                </div>
            ),
            sortable: false,
        },
        {
            name: "Email",
            selector: (row) => row.email,
            cell: (row) => (
                <span>{row.email}</span>
            ),
            sortable: false,
        },
        {
            name: "Phone number",
            selector: (row) => row.phonenumber,
            cell: (row) => (
                <span>{row.phonenumber}</span>
            ),
            sortable: false,
        },
        {
            name: "Joined at",
            selector: (row) => row.created_at,
            cell: (row) => (
                <span>{new Date(row.created_at).toDateString()}</span>
            ),
            sortable: false,
        },
        {
            name: "action",
            cell: (row) => {
                const isRestricted = row.blacklisted.some(r => ownerHotels.includes(r))

                return (
                    <div className="text-end w-100">
                        <Button style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "0px", padding: "5px 10px", fontSize: "13px", width: "94px" }} className="btn-custom" onClick={() => {
                            setUserInfo(row)
                            setRestrictUser(isRestricted ? false : true)
                            setBlockUserModal(true)
                        }}>
                            <MdBlock size="18" style={{ marginRight: "8px" }} /><span>{isRestricted ? "Unblock" : "Block"}</span>
                        </Button>
                    </div>
                )
            },
            sortable: false,

        },

    ]

    // console.log({ users })

    return (
        <Layout title="Customers List" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Customers List</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Customers</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                    <Block.HeadContent>
                        <ul className="d-flex">
                            <li>
                                <Button className="d-md-none" size="md" variant="primary" onClick={() => { navigate("/user-manage/user-edit") }}>
                                    <Icon name="plus" />
                                    <span>Add</span>
                                </Button>
                            </li>
                        </ul>
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>
            <Block>
                <Card>
                    {loading ? (
                            <div
                            style={{
                                minHeight: "500px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            >
                            <PulseLoader color="grey" />
                            </div>
                    ) : (
                        users && <DataTable
                            tableClassName="data-table-head-light table-responsive"
                            data={users}
                            search={"email"}
                            columns={ownerColumns}
                            setFilteredTable={setUsers}
                            originalTable={originalUsersList}
                        />
                    )}
                </Card>
            </Block>

            {/* <Modal show={deleteModal} onHide={handleCloseModal}>
                <div className="modal-content">
                    <div className="modal-body p-5 text-center">
                        <div className="media media-rounded media-xxl media-middle mx-auto text-bg-danger mb-4"><em className="icon ni ni-cross"></em></div>
                        <h3>Are You Sure ?</h3>
                        <p className="small">This room will be removed permanently.</p>
                        <ul className="d-flex gap g-3 justify-content-center pt-1">
                            <li><Button variant="success" onClick={() => { deleteUser() }}>Yes Delete it!</Button></li>
                            <li><Button variant="danger" className="btn-soft" onClick={handleCloseModal}>Cancel</Button></li>
                        </ul>
                    </div>
                    <button type="button" className="btn-close position-absolute top-0 end-0 p-3" onClick={handleCloseModal} aria-label="Close"></button>
                </div>
            </Modal> */}

            <Modal show={blockUserModal} onHide={() => setBlockUserModal(false)}>
                <div className="modal-content">
                    <div className="modal-body p-5 text-center">
                        <div className="media media-rounded media-xxl media-middle mx-auto text-bg-danger mb-4"><em className="icon ni ni-cross"></em></div>
                        <h3>Are You Sure ?</h3>
                        <p className="small">You are about to {restrictUser ? "block" : "unblock"} {userInfo && userInfo.first_name} ({userInfo && userInfo.email})</p>
                        <ul className="d-flex gap g-3 justify-content-center pt-1">
                            <li><Button variant="success" onClick={() => handleRestrictUser()}>{restrictUser ? "Block" : "Unblock"}</Button></li>
                            <li><Button variant="danger" className="btn-soft" onClick={() => setBlockUserModal(false)}>Cancel</Button></li>
                        </ul>
                    </div>
                    <button type="button" className="btn-close position-absolute top-0 end-0 p-3" onClick={() => setBlockUserModal(false)} aria-label="Close"></button>
                </div>
            </Modal>

        </Layout>
    )
}

export default HotelUsers