import { useEffect, useState } from "react"
import { Card, Button, Modal, Form, Row, Col, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"
import Layout from "../../layout/default"
import Block from "../../components/Block/Block"
import DataTable from "../../components/DataTable/DataTable"
import { Icon } from "../../components"
import { bookingColumns } from "../../store/users/BookingData"
import axios from "../../services/axios"
import { getCookie } from "../../services/cookieHandling"
import decode from "jwt-decode"
import { PulseLoader } from "react-spinners"

function BookingsList() {
	const [bookings, setBookings] = useState(null)
	const [triggerRefresh, setTriggerRefresh] = useState(0)
	const [loading, setLoading] = useState(false)
	const [filterName, setFilterName] = useState(false)

	const token = localStorage.getItem("token")
	const hotelidentifier = localStorage.getItem("hotelidentifier")

	const decoded = token && decode(token)

	const getAllBookingsList = async () => {
		setLoading(true)
		// Get bookings for staff
		if (decoded.user.role === "Staff") {
			const { data, status } = await axios.post("/Booking/staff-hotels", {
				token,
			})

			if (data.success) {
				setBookings(data.bookings)
			}
		}
		// Get booking list for admin and hotel owners
		else {
			const { data, status } = await axios.get(
				"Booking/list" +
				(getCookie("role") == "Hotel" || getCookie("role") == "Staff"
					? `?author_id=${getCookie("author_id")}&payment_method=Offline&hotelidentifier=${hotelidentifier}`
					: `?payment_method=Offline&hotelidentifier=${hotelidentifier}`)
			)

			if (status === 200 || status === 201) {
				setBookings(data?.results)
			}
		}
		setLoading(false)
	}

	useEffect(() => {
		getAllBookingsList()
	}, [triggerRefresh])


	console.log({ bookings })

	const tableColumns = bookingColumns(setTriggerRefresh)

	return (
		<Layout title="Bookings" content="container">
			<Block.Head>
				<Block.HeadBetween>
					<Block.HeadContent>
						<Block.Title tag="h2">Bookings</Block.Title>
						<nav>
							<ol className="breadcrumb breadcrumb-arrow mb-0">
								<li className="breadcrumb-item">
									<Link to="/home">Home</Link>
								</li>
								<li className="breadcrumb-item active" aria-current="page">
									Bookings
								</li>
							</ol>
						</nav>
					</Block.HeadContent>
					<Block.HeadContent>
						<ul className="d-flex">
							<li>
								<Link to="/bookings/add-booking">
									<Button className="d-inline-flex" variant="primary">
										<Icon name="plus" />
										<span>Add Booking</span>
									</Button>
								</Link>
							</li>
						</ul>
					</Block.HeadContent>
				</Block.HeadBetween>
			</Block.Head>
			<Block>
				<Card>
					{loading ? (
						<div
							style={{
								minHeight: "500px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<PulseLoader color="grey" />
						</div>
					) : (
						<DataTable
							tableClassName="data-table-head-light table-responsive"
							data={bookings ?? []}
							searchTerm={"Hotel Title"}
							originalTable={bookings}
							columns={tableColumns}
						/>
					)}
				</Card>
			</Block>
		</Layout>
	)
}

export default BookingsList
