import "./css/PushNotifications.css"
import Layout from "../layout/default"
import { useState } from "react"
import { FaBell, FaSpinner } from "react-icons/fa6"
import Projection from "../assets/images/undraw_projections_re_ulc6.svg"
import axios from "../services/axios"
import { Spinner } from "react-bootstrap"
import { toast } from "react-toastify"

const PushNotifications = () => {
    const [usersNotifications, setUsersNotifications] = useState("")
    const [subject, setSubject] = useState("")
    const [sendTo, setSendTo] = useState("users")
    const [loading, setLoading] = useState(false)

    const token = localStorage.getItem("token")

    const sendPushNotification = async (type) => {
        setLoading(type)
        const { data, status } = await axios.post("/Notification/push-notification", {
            token, 
            subject,
            text: usersNotifications, 
            type, 
            sendto: sendTo
        })

        if (data.success) {
            toast.success(data.message)
        }
        setLoading(false)
    }

    return (
        <Layout title="Coupons" content="container">
            <div className="_0puf">
                <div>
                    <div className="yarning-reed">
                        <FaBell />
                        <h3>Push Notifications</h3>
                    </div>
                    <div className="proxy-sore">
                        <h4>Send push notifications to users or hotel owners</h4>
                        <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "20px" }}>
                            <input 
                                id="users"
                                type="radio"
                                value="users"
                                checked={sendTo === "users"} 
                                onChange={e => setSendTo(e.target.value)}
                            />
                            <label htmlFor="users">Users</label>
                            <input 
                                id="owners"
                                type="radio"
                                value="owners"
                                checked={sendTo === "owners"} 
                                onChange={e => setSendTo(e.target.value)}
                            />
                            <label htmlFor="owners">Owners</label>
                        </div>
                        <input 
                            style={{ marginBottom: "10px", borderRadius: "8px", border: "1px solid grey", outline: "none", paddingLeft: "10px", width: "400px", fontSize: "14px" }}
                            placeholder="Subject"
                            value={subject}
                            onChange={e => setSubject(e.target.value)}
                        />
                        <textarea
                            placeholder="Please input a text to send"
                            value={usersNotifications}
                            onChange={e => setUsersNotifications(e.target.value)}
                        />
                        <div className="liker-hop">
                            <button onClick={() => sendPushNotification("email")}>
                                Send E-mail
                            </button>
                            <button onClick={() => sendPushNotification("push")}>
                                Send Push Notification
                            </button>
                        </div>
                    </div>
                </div>
                <div className="ambiance-kea">
                    <img
                        src={Projection}
                        alt=""
                    />
                </div>

            </div>
        </Layout>
    )
}

export default PushNotifications
