import { useEffect, useState } from 'react'
import { Card, Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Layout from '../../layout/default'
import Block from '../../components/Block/Block'
import DataTable from '../../components/DataTable/DataTable'
import { Icon } from '../../components'
import { bookingColumns } from '../../store/users/PayAtHotel'
import axios from '../../services/axios'
import { getCookie } from '../../services/cookieHandling'
import decode from "jwt-decode"
import { PulseLoader } from "react-spinners"
import "./css/PayAtHotel.css"

function PayAtHotel() {
    const [bookings, setBookings] = useState(null)
    const [triggerRefresh, setTriggerRefresh] = useState(0)
    const [loading, setLoading] = useState(false)
    const [playLoading, setPlayLoading] = useState(false)
    const [playIndex, setPlayIndex] = useState(0)

    const token = localStorage.getItem("token")

    const decoded = token && decode(token)

    const getAllBookingsList = async () => {
        setLoading(true)

        const { data, status } = await axios.post("Booking/pay-at-hotel-list", { token })

        if (data.success) {
            setBookings(data.bookings)
        }

        setLoading(false)
    }

    useEffect(() => {
        getAllBookingsList()
    }, [triggerRefresh])

    const tableColumns = bookingColumns(playLoading, setPlayLoading, playIndex, setPlayIndex, setTriggerRefresh)

    return (
        <div className="_9lkm">
            <Layout title="Bookings" content="container">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <Block.Title tag="h2">Bookings</Block.Title>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Bookings</li>
                                </ol>
                            </nav>
                        </Block.HeadContent>
                    </Block.HeadBetween>
                </Block.Head>
                <Block>
                    <Card>
                        {loading ? <div style={{ minHeight: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}><PulseLoader color="grey" /></div> : <DataTable tableClassName="data-table-head-light table-responsive" data={bookings ?? []} columns={tableColumns} />}
                    </Card>
                </Block>
            </Layout>
        </div>
    )
}

export default PayAtHotel
