import React, { useState, useEffect } from 'react'
import axios from '../services/axios'
import { getCookie } from '../services/cookieHandling'
import { Logo } from '../components'
import { Container, Navbar, Card } from 'react-bootstrap'
import { useNavigate } from "react-router"
import DefaultProfile from "../assets/images/DefaultProfile.jpg"

function HotelSelector() {
	const [loading, setLoading] = useState(false)
	const [hotels, setHotels] = useState([])

	const navigate = useNavigate()

	const fetchHotels = async () => {
		const { data, status } = await axios.get(`Hotel/list${getCookie("role") == "Hotel" ? '?author_id=' + getCookie("author_id") : ""}`)

		if (status === 200 || status === 201) {
			// Hotel owner has no hotels registered
			if (data.results.length === 0) {
				navigate("/")
			}
			// Hotel owner has one hotels registered - auto login with existing hotel
			else if (data.results.length === 1) {
				const hoteldata = data.results[0]

				localStorage.setItem("hotelidentifier", hoteldata._id)
				localStorage.setItem("selectedHotel", hoteldata.title)
				navigate("/")
			}
			// More than one hotels - ask to select a hotel
			else {
				setHotels(data.results)
			}
		}
	}

	useEffect(() => {
		fetchHotels()
	}, [])

	const handleSelectHotel = (hotel) => {
		// Login as seperate hotel
		if (hotel) {
			localStorage.setItem("hotelidentifier", hotel._id)
			localStorage.setItem("selectedHotel", hotel.title)
		}
		// Login as hotel administrator
		else {
			localStorage.removeItem("hotelidentifier")
			localStorage.removeItem("selectedHotel")
		}
		
		navigate("/")
	}

	return (
		<>
			<Navbar className="bg-body-tertiary" style={{ backgroundColor: 'black' }}>
				<Container>
					<Navbar.Brand href="#home" style={{ marginTop: '7px' }}><Logo /></Navbar.Brand>
				</Container>
			</Navbar>
			<div style={{ width: "100vw", display: "flex", flexDirection: "column", alignItems: "center" }}>
				<div style={{ color: "#ff6431", fontSize: "30px", fontWeight: "600", marginTop: "40px", marginBottom: "40px" }}>Please select a hotel</div>
				<ul style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
					<li onClick={() => handleSelectHotel(undefined)} key="HA-1">
						<Card style={{ cursor: "pointer", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", width: "280px", overflow: "hidden" }}>
							<div style={{ height: '200px', display: "flex", alignItems: "center", justifyContent: "center" }}>
								<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
									<img style={{ width: "40px", height: "40px" }} src={DefaultProfile} alt="" />
									<div style={{ display: "flex", flexDirection: "column" }}>
										<div>Admin</div>
										<div style={{ color: "grey" }}>Log in to view all hotels</div>
									</div>
								</div>
							</div>
							<Card.Body style={{ background: "linear-gradient(90deg, rgba(255,207,44,1) 0%, rgba(254,200,95,1) 32%, rgba(254,255,167,1) 76%, rgba(255,129,0,0.1540265764508929) 100%)" }}>
								<Card.Title>Hotel Admin</Card.Title>
							</Card.Body>
						</Card>
					</li>
					{hotels.map(hotel => (
						<li onClick={() => handleSelectHotel(hotel)} key={hotel._id}>
							<Card style={{ cursor: "pointer", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", width: "280px" }}>
								<Card.Img variant="top" src={hotel.banner_image} style={{ width: '400px', height: '200px', objectFit: 'cover' }} />
								<Card.Body>
									<Card.Title>{hotel.title}</Card.Title>
								</Card.Body>
							</Card>
						</li>
					))}
				</ul>
			</div>
		</>
	)
}

export default HotelSelector