import { useEffect, useState } from 'react';
import { Card, Button, Modal, Form, Row, Col, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MediaGroup, Media, MediaText, Image, Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem, Select } from "../../components";
import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import DataTable from '../../components/DataTable/DataTable';
// import { Icon, Select } from '../../components';
// import  { couponrColumns } from '../../store/users/CouponData';
import axios from '../../services/axios';
import { PulseLoader } from 'react-spinners';
import moment from "moment";
import { toast } from 'react-toastify'
function CouponList() {
    const [coupons, setcoupon] = useState(null);
    const [deleteModal, setdeleteModal] = useState(false)
    const [loading, setLoading] = useState(false);
    const [couponId, setcouponId] = useState(null);
    const getAllAminitiesList = async () => {
        setLoading(true)
        const { data, status } = await axios.get("Coupon/list");
        if (status === 200 || status === 201) {
            console.log({ data })
            setcoupon(data?.results)
        }
        setLoading(false)
    }
    useEffect(() => {
        getAllAminitiesList();
    }, [])

const couponrColumns = [
        {
            name: "Coupon Name",
            selector: (row) => row.name,
            cell: (row) => (
                <span>{row.name}</span>
            ),
            sortable: false,
            
        },
        {
            name: "Coupon Code",
            selector: (row) => row.code,
            cell: (row) => (
                <span>{row.code}</span>
            ),
            sortable: false,
            
        },
        {
            name: "Coupon Amount",
            selector: (row) => row.amount,
            cell: (row) => (
                <span>{row.amount}</span>
            ),
            sortable: false,
            
        },
        {
            name: "End Date",
            selector: (row) => row.endate,
            cell: (row) => (
                <span>{moment(row.endate).format("DD MMM YYYY")}</span>
            ),
            sortable: false,
            
        },
        {
            name: "Discount Type",
            selector: (row) => row.discount_type,
            cell: (row) => (
                <span>{row.discount_type}</span>
            ),
            sortable: false,
            
        },
        {
            name: "status",
            selector: (row) => row.status,
            cell: (row) => (
                <span className={`badge text-bg-${
                    row.status === "Active" ? "success-soft" 
                    : row.status === "Pending" ? "warning-soft" 
                    : row.status === "Inactive" ? "secondary-soft" 
                    : "primary-soft"}`
                }>
                {row.status ? row.status : 'General'}
                </span>
            ),
            sortable: false,
        },
        {
            name: "Minimum spend",
            selector: (row) => row.minimumspend,
            cell: (row) => (
                <span>
                { row.minimumspend}
                </span>
            ),
            sortable: false,
        },
        {
            name: "Maximum spend",
            selector: (row) => row.maximumspend,
            cell: (row) => (
                <span>
                { row.maximumspend}
                </span>
            ),
            sortable: false,
        },
        {
            name: "action",
            cell: (row) => (
                <div className="text-end w-100">
                    <Dropdown>
                        <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                            <Icon name="more-v"></Icon>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                            <div className="dropdown-content py-1">
                                <LinkList className="link-list-hover-bg-primary link-list-md">
                                    <LinkListItem to={`/coupon/add-coupon/${row._id}`}>
                                        <Icon name="edit"></Icon><span>Edit</span>
                                    </LinkListItem>
                                    <li>
                                            <Button className="btn-custom" onClick={() => {setcouponId(row._id); handleShowModal()}}>
                                                <Icon name="trash"></Icon><span>Delete</span>
                                            </Button>
                                        </li>
                                </LinkList>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            ),
            sortable: false,
            
        },
        
    ];

    const handleShowModal = () => setdeleteModal(true)
	const handleCloseModal = () => setdeleteModal(false)
    const deleteCoupon = async () => {
		const { data, status } = await axios.delete(`Coupon/delete/${couponId}`)
		if (status == 204) {
			toast.success(`Coupon deleted successfully`)
			getAllAminitiesList()
			setdeleteModal(false)
		}
	}
  return (
    <Layout title="Coupons" content="container">
        <Modal show={deleteModal} onHide={handleCloseModal}>
				<div className="modal-content">
					<div className="modal-body p-5 text-center">
						<div className="media media-rounded media-xxl media-middle mx-auto text-bg-danger mb-4"><em className="icon ni ni-cross"></em></div>
						<h3>Are You Sure ?</h3>
						<p className="small">This coupon will be removed permanently.</p>
						<ul className="d-flex gap g-3 justify-content-center pt-1">
							<li><Button variant="success" onClick={() => { deleteCoupon() }}>Yes Delete it!</Button></li>
							<li><Button variant="danger" className="btn-soft" onClick={handleCloseModal}>Cancel</Button></li>
						</ul>
					</div>
					<button type="button" className="btn-close position-absolute top-0 end-0 p-3" onClick={handleCloseModal} aria-label="Close"></button>
				</div>
		</Modal>
        <Block.Head>
            <Block.HeadBetween>
                <Block.HeadContent>
                    <Block.Title tag="h2">Coupons List
        </Block.Title>
                    <nav>
                        <ol className="breadcrumb breadcrumb-arrow mb-0">
                            <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Coupons</li>
                        </ol>
                    </nav>
                </Block.HeadContent>
                <Block.HeadContent>
                    <ul className="d-flex">
                        <li>
                            <Link to="/coupon/add-coupon">
                                <Button className="d-inline-flex" variant="primary">
                                    <Icon name="plus"/>
                                    <span>Add Coupon</span>
                                </Button>
                            </Link>
                        </li>
                    </ul>
                </Block.HeadContent>
            </Block.HeadBetween>
        </Block.Head>

      <Block>
        <Card>
            {loading ? (
                <div
                style={{
                minHeight: "500px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                }}
                >
                <PulseLoader color="grey" />
                </div>
            ) : (
                <DataTable
                    tableClassName="data-table-head-light table-responsive" 
                    data={coupons ?? []} 
                    originalTable={coupons}
                    searchTerm={'Coupon Name'}
                    columns={couponrColumns}
                />
            )}
        </Card>
      </Block>
    </Layout>
  )
}

export default CouponList;

export const coupons = [
    {
        code:'uid01',
        name: 'FLAT50',
        amount:'1000',
        end:'22-22-2022',
        status:'Active',
    },
];