import React, { useEffect } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { useState } from "react";
import axios from "../../services/axios";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { PulseLoader } from 'react-spinners'

const AccountDetails = () => {
  const [stripeaccount, setStripeaccount] = useState(undefined)
  const [onboardinglink, setOnboardingLink] = useState(undefined)
  const [accountstatus, setAccountStatus] = useState(false)
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");

  const [tabledata, setTabledata] = useState([]);

  const bankdetailsgCol = [
    {
      name: "ID",
      selector: (row) => row.id,
      cell: (row) => <span>{row.id}</span>,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => <span>{row.email}</span>,
    },
    {
      name: "Account",
      selector: (row) => row.accountnumber,
      cell: (row) => <span>{row.accountnumber}</span>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => <span style={{ color: row.status === "active" ? "green" : "red" }}>{row.status}</span>,
    },
    {
      name: "Reason",
      selector: (row) => row.reason,
      cell: (row) => <span>{row.reason}</span>,
    },
  ];

  const getOnboardingLink = async () => {
    const { data, status } = await axios.post("/Payments/register-user", { token })

    if (data.success) {
      setOnboardingLink(data.accountLink.url)
    }
  }

  const getStripeAccountStatus = async () => {
    const { data, status } = await axios.post("/Payments/fetch-status", { token })

    if (data.success) {
      setStripeaccount(data.account)
      setTabledata([{
        id: data.account.id,
        status: data.account.capabilities.transfers ? "active" : "inactive",
        reason: data.account.capabilities.transfers ? "" : (data.account.details_submitted ? "Verification pending" : "Please fill onboarding form"),
        accountnumber: data.account.capabilities.transfers ? "xxxx" + data.account.external_accounts.data[0].last4 : "",
        email: data.account.email ? data.account.email : undefined
      }])

      if (data.account.details_submitted) {
        if (data.account.capabilities.transfers === "active") {
          setAccountStatus("active")
        } else {
          setAccountStatus("verification")
        }
      }
    }
  }

  console.log(stripeaccount)

  useEffect(() => {
    getOnboardingLink()
    getStripeAccountStatus()
  }, [])

  useEffect(() => {
    getStripeAccountStatus()
  },[onboardinglink])

  return (
    <Layout title="Add Amenity" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Add Bank details</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/home">Home</Link>
                </li>
                <li
                  className="breadcrumb-item active"
                  aria-current="/bookings/add-account"
                >
                  Add Bank details
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Card>
        {!tabledata.length ? (
            <div
            style={{
                minHeight: "500px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
            >
            <PulseLoader color="grey" />
            </div>
        ) : (
          <DataTable
            tableClassName="data-table-head-light table-responsive"
            data={tabledata}
            columns={bankdetailsgCol}
          />
        )}
        </Card>
      </Block>
      {accountstatus !== "active" ? (accountstatus === "verification" ? <p style={{ marginTop: "20px" }}>Please wait while stipe verifies bank account details</p> : <button onClick={e => {
        e.preventDefault()
        window.location = onboardinglink
      }}>Start onboarding</button>) : <p style={{ marginTop: "20px" }}>Bank account linked success</p>}
    </Layout>
  );
};

export default AccountDetails;
