import { useEffect, useState } from "react"
import { Card, Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import Layout from "../../layout/default"
import Block from "../../components/Block/Block"
import DataTable from "../../components/DataTable/DataTable"
import axios from "../../services/axios"
import { getCookie } from "../../services/cookieHandling"
import "./css/BookingCommission.css"
import { url } from "../../services/axios"
import { VscPerson } from "react-icons/vsc"
import { MdOutlineChildCare } from "react-icons/md"
import Form from "react-bootstrap/Form"
import { PulseLoader } from 'react-spinners'

function BookingsCommissionOwner() {
    const [commissions, setCommissions] = useState([])
    const [bookdetails, setBookdetails] = useState([])
    const [originalBookdetails, setOriginalBookdetails] = useState([])
    const [totalBalance, setTotalBalance] = useState(0)
    const [checkinDate, setCheckinDate] = useState(undefined)
    const [checkoutDate, setCheckoutDate] = useState(undefined)
    const [loading, setLoading] = useState(false);

    const token = localStorage.getItem("token")
    const hotelidentifier = localStorage.getItem("hotelidentifier")

    const fetchAllCommissions = async () => {
        setLoading(true)
        const { data, status } = await axios.post(`${url}/Booking/get-commissions`, { token, hotelidentifier })

        if (data.success) {
            setCommissions(data.commissions)
        } else {
            console.log(data.message)
        }
        setLoading(false)
    }

    console.log({ commissions })

    useEffect(() => {
        fetchAllCommissions()
    }, [])

    useEffect(() => {
        setBookdetails(commissions.map(commission => {
            let bookingid = commission.bookingid.booking_id

            if (commission.hotelid) {
                const [hotelprefix, hotelsuffix] = commission.hotelid.title.split(" ")

                const hotelinitials = hotelsuffix ? hotelprefix[0].toUpperCase() + hotelsuffix[0].toUpperCase() : hotelprefix[0].toUpperCase()

                const today = new Date()

                bookingid = hotelinitials + (today.getMonth() + 1).toString().padStart(2, '0') + today.getFullYear().toString().slice(-2) + commission.bookingid.booking_id
            }
            return {
                ID: bookingid,
                NAME: commission.customerid && commission.customerid.first_name,
                PAYMENT_AMOUNT: commission.payment,
                SUPERADMIN_AMOUNT: commission.superadminamount,
                HOTEL_NAME: commission.hotelid && commission.hotelid.title,
                HOTEL_PRICE: commission.hotelprice,
                OWNER_AMOUNT: commission.hotelowneramount,
                GATEWAY_CHARGE: commission.gatewaycharge,
                GST_CHARGE: commission.gstcharge,
                PERCENTAGE_OWNERSHIP: commission.hotelid && commission.hotelid.percentageOwnership,
                ADULTS_COUNT: commission.bookingid.no_of_adults,
                CHILDREN_COUNT: commission.bookingid.no_of_chlidrens,
                ROOMS_COUNT: commission.bookingid.no_of_rooms,
                BOOKING_STATUS: commission.bookingid.booking_status,
                CHECKIN_DATE: commission.bookingid.checkin_date,
                CHECKOUT_DATE: commission.bookingid.checkout_date,
                INSTRUCTIONS: commission.bookingid.instructions
            }
        }))
        setOriginalBookdetails(commissions.map(commission => {
            let bookingid = commission.bookingid.booking_id

            if (commission.hotelid) {
                const [hotelprefix, hotelsuffix] = commission.hotelid.title.split(" ")

                const hotelinitials = hotelsuffix ? hotelprefix[0].toUpperCase() + hotelsuffix[0].toUpperCase() : hotelprefix[0].toUpperCase()

                const today = new Date()

                bookingid = hotelinitials + (today.getMonth() + 1).toString().padStart(2, '0') + today.getFullYear().toString().slice(-2) + commission.bookingid.booking_id
            }

            return {
                ID: bookingid,
                NAME: commission.customerid && commission.customerid.first_name,
                PAYMENT_AMOUNT: commission.payment,
                SUPERADMIN_AMOUNT: commission.superadminamount,
                HOTEL_NAME: commission.hotelid && commission.hotelid.title,
                HOTEL_PRICE: commission.hotelprice,
                OWNER_AMOUNT: commission.hotelowneramount,
                GATEWAY_CHARGE: commission.gatewaycharge,
                GST_CHARGE: commission.gstcharge,
                PERCENTAGE_OWNERSHIP: commission.hotelid && commission.hotelid.percentageOwnership,
                ADULTS_COUNT: commission.bookingid.no_of_adults,
                CHILDREN_COUNT: commission.bookingid.no_of_chlidrens,
                ROOMS_COUNT: commission.bookingid.no_of_rooms,
                BOOKING_STATUS: commission.bookingid.booking_status,
                CHECKIN_DATE: commission.bookingid.checkin_date,
                CHECKOUT_DATE: commission.bookingid.checkout_date,
                INSTRUCTIONS: commission.bookingid.instructions
            }
        }))
    }, [commissions])

    useEffect(() => {
        if (String(checkinDate) === "Invalid Date") {
            setCheckinDate(undefined)
        }
        if (String(checkoutDate) === "Invalid Date") {
            setCheckoutDate(undefined)
        }

        if ((checkinDate && String(checkinDate) !== "Invalid Date") || checkoutDate && String(checkoutDate) !== "Invalid Date") {
            setBookdetails(commissions.map(commission => {
                const authorinfo = JSON.parse(commission.bookingid.hotel_infomration)[0].hotel_id.author_id

                let bookingid = commission.bookingid.booking_id

				if (commission.hotelid) {
					const [hotelprefix, hotelsuffix] = commission.hotelid.title.split(" ")

					const hotelinitials = hotelsuffix ? hotelprefix[0].toUpperCase() + hotelsuffix[0].toUpperCase() : hotelprefix[0].toUpperCase()

					const today = new Date()

					bookingid = hotelinitials + (today.getMonth() + 1).toString().padStart(2, '0') + today.getFullYear().toString().slice(-2) + commission.bookingid.booking_id
				}

                return {
                    ID: bookingid,
                    NAME: commission.customerid && commission.customerid.first_name,
                    PAYMENT_AMOUNT: commission.payment,
                    SUPERADMIN_AMOUNT: commission.superadminamount,
                    HOTEL_NAME: commission.hotelid && commission.hotelid.title,
                    HOTEL_OWNER: `${authorinfo.first_name} ${authorinfo.last_name}`,
                    HOTEL_PRICE: commission.hotelprice,
                    OWNER_AMOUNT: commission.hotelowneramount,
                    GATEWAY_CHARGE: commission.gatewaycharge,
                    GST_CHARGE: commission.gstcharge,
                    PERCENTAGE_OWNERSHIP: commission.hotelid && commission.hotelid.percentageOwnership,
                    ADULTS_COUNT: commission.bookingid.no_of_adults,
                    CHILDREN_COUNT: commission.bookingid.no_of_chlidrens,
                    ROOMS_COUNT: commission.bookingid.no_of_rooms,
                    BOOKING_STATUS: commission.bookingid.booking_status,
                    CHECKIN_DATE: commission.bookingid.checkin_date,
                    CHECKOUT_DATE: commission.bookingid.checkout_date,
                    INSTRUCTIONS: commission.bookingid.instructions
                }
            }).filter(item => {
                if (checkinDate && checkoutDate) {
                    if ((new Date(checkinDate).toDateString() == new Date(item.CHECKIN_DATE).toDateString()) && (new Date(item.CHECKOUT_DATE).toDateString() == new Date(checkoutDate).toDateString())) {
                        return true
                    }
                    return false
                }

                if (checkinDate && (new Date(checkinDate).toDateString() == new Date(item.CHECKIN_DATE).toDateString())) {
                    return true
                }

                if (checkoutDate && (new Date(item.CHECKOUT_DATE).toDateString() == new Date(checkoutDate).toDateString())) {
                    return true
                }

                return false
            })
            )
        }
        else {
            setBookdetails(commissions.map(commission => {
                const authorinfo = JSON.parse(commission.bookingid.hotel_infomration)[0].hotel_id.author_id

                let bookingid = commission.bookingid.booking_id

                if (commission.hotelid) {
                    const [hotelprefix, hotelsuffix] = commission.hotelid.title.split(" ")

                    const hotelinitials = hotelsuffix ? hotelprefix[0].toUpperCase() + hotelsuffix[0].toUpperCase() : hotelprefix[0].toUpperCase()

                    const today = new Date()

                    bookingid = hotelinitials + (today.getMonth() + 1).toString().padStart(2, '0') + today.getFullYear().toString().slice(-2) + commission.bookingid.booking_id
                }

                return {
                    ID: bookingid,
                    NAME: commission.customerid && commission.customerid.first_name,
                    PAYMENT_AMOUNT: commission.payment,
                    SUPERADMIN_AMOUNT: commission.superadminamount,
                    HOTEL_NAME: commission.hotelid && commission.hotelid.title,
                    HOTEL_OWNER: `${authorinfo.first_name} ${authorinfo.last_name}`,
                    HOTEL_PRICE: commission.hotelprice,
                    OWNER_AMOUNT: commission.hotelowneramount,
                    GATEWAY_CHARGE: commission.gatewaycharge,
                    GST_CHARGE: commission.gstcharge,
                    PERCENTAGE_OWNERSHIP: commission.hotelid && commission.hotelid.percentageOwnership,
                    ADULTS_COUNT: commission.bookingid.no_of_adults,
                    CHILDREN_COUNT: commission.bookingid.no_of_chlidrens,
                    ROOMS_COUNT: commission.bookingid.no_of_rooms,
                    BOOKING_STATUS: commission.bookingid.booking_status,
                    CHECKIN_DATE: commission.bookingid.checkin_date,
                    CHECKOUT_DATE: commission.bookingid.checkout_date,
                    INSTRUCTIONS: commission.bookingid.instructions
                }
            }))
        }
    }, [checkinDate, checkoutDate])

    useEffect(() => {
        let sum = 0
        bookdetails.forEach(item => {
            sum += Number(item.OWNER_AMOUNT)
        })
        setTotalBalance(sum)
    }, [bookdetails])

    const bookingCol = [
        {
            name: "BOOKING ID",
            selector: (row) => row.ID,
            cell: (row) => <span style={{ fontSize: "10px", wordBreak: "normal" }}>{row.ID}</span>,
        },
        {
            name: "HOTEL NAME",
            selector: (row) => row.HOTEL_NAME,
            cell: (row) => <span>{row.HOTEL_NAME}</span>,
        },
        {
            name: "HOTEL PRICE",
            selector: (row) => row.HOTEL_PRICE,
            cell: (row) => <span>₹ {row.HOTEL_PRICE}</span>,
        },
        {
            name: "CUSTOMER NAME",
            selector: (row) => row.NAME,
            cell: (row) => <span>{row.NAME}</span>,
        },
        {
            name: <span style={{ width: "100%", display: "flex", justifyContent: "center" }}>PERSON COUNT</span>,
            selector: (row) => row.ADULTS_COUNT,
            cell: (row) => (
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <VscPerson style={{ width: "40px", height: "25px", backgroundColor: "#F4DFB6", fill: "#9A4444", padding: "0px 4px", boxSizing: "border-box", borderRadius: "5px" }} />
                        <span style={{ width: "100%", display: "flex", justifyContent: "center", fontWeight: "600", color: "grey" }}>
                            {row.ADULTS_COUNT}
                        </span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <MdOutlineChildCare style={{ width: "40px", height: "25px", backgroundColor: "#F4DFB6", fill: "#9A4444", padding: "0px 4px", boxSizing: "border-box", borderRadius: "5px" }} />
                        <span style={{ width: "100%", display: "flex", justifyContent: "center", fontWeight: "600", color: "grey" }}>
                            {row.CHILDREN_COUNT}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            name: <span style={{ width: "100%", display: "flex", justifyContent: "center" }}>CHECKIN DATE</span>,
            selector: (row) => row.CHECKIN_DATE,
            cell: (row) => <span style={{ width: "100%", display: "flex", justifyContent: "center" }}>{new Date(row.CHECKIN_DATE).toDateString()}</span>,
        },
        {
            name: <span style={{ width: "100%", display: "flex", justifyContent: "center" }}>CHECKOUT DATE</span>,
            selector: (row) => row.CHECKOUT_DATE,
            cell: (row) => <span style={{ width: "100%", display: "flex", justifyContent: "center" }}>{new Date(row.CHECKOUT_DATE).toDateString()}</span>,
        },
        {
            name: <span style={{ width: "100%", display: "flex", justifyContent: "center", textAlign: "center" }}>BOOKED ROOMS COUNT</span>,
            selector: (row) => row.ROOMS_COUNT,
            cell: (row) => <span style={{ width: "100%", display: "flex", justifyContent: "center" }}>{row.ROOMS_COUNT}</span>,
        },
        {
            name: <span style={{ width: "100%", display: "flex", justifyContent: "center", textAlign: "center" }}>BOOKING STATUS</span>,
            selector: (row) => row.BOOKING_STATUS,
            cell: (row) => (
                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span style={{ backgroundColor: row.BOOKING_STATUS === "Confirmed" ? "rgb(108, 177, 108)" : "rgb(255, 100, 100)", color: "white", width: "70px", fontSize: "12px", height: "24px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "5px" }}>{row.BOOKING_STATUS}</span>
                </div>
            ),
        },
        {
            name: "USER NOTES",
            selector: (row) => row.INSTRUCTIONS,
            cell: (row) => <span style={{ color: row.INSTRUCTIONS ? undefined : "grey" }}>{row.INSTRUCTIONS ? row.INSTRUCTIONS : "None"}</span>,
        },
        {
            name: "USER PAID",
            selector: (row) => row.PAYMENT_AMOUNT,
            cell: (row) => <span>₹ {row.PAYMENT_AMOUNT}</span>,
        },
        {
            name: "YOUR AMOUNT",
            selector: (row) => row.OWNER_AMOUNT,
            cell: (row) => <span>₹ {row.OWNER_AMOUNT}</span>,
        },
        {
            name: "SUPERADMIN SHARE",
            selector: (row) => row.SUPERADMIN_AMOUNT,
            cell: (row) => <span>₹ {row.SUPERADMIN_AMOUNT} <span style={{ backgroundColor: "green", color: "white", padding: "3px 6px", borderRadius: "6px", fontSize: "10px", marginLeft: "10px" }}>{100 - row.PERCENTAGE_OWNERSHIP} %</span></span>,
        },
        {
            name: "GATEWAY CHARGE",
            selector: (row) => row.GATEWAY_CHARGE,
            cell: (row) => <span>₹ {row.GATEWAY_CHARGE}</span>,
        },
        {
            name: "GST",
            selector: (row) => row.GST_CHARGE,
            cell: (row) => <span>₹ {row.GST_CHARGE}</span>,
        }
    ]

    return (
        <Layout title="Bookings" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Payments</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item">
                                    <Link to="/home">Home</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Payment details
                                </li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>
            <Block>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <span>Filters</span>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <div style={{ width: "170px", marginBottom: "20px" }}>
                            <span style={{ fontSize: "14px", color: "grey" }}>Check-in date</span>
                            <Form.Control type="date" onChange={e => setCheckinDate(new Date(e.target.value))} />
                        </div>
                        <div style={{ width: "170px", marginBottom: "20px" }}>
                            <span style={{ fontSize: "14px", color: "grey" }}>Check-out date</span>
                            <Form.Control type="date" onChange={e => setCheckoutDate(new Date(e.target.value))} />
                        </div>
                    </div>
                </div>
                <Card>
                    {!bookdetails.length ? (
                            <div
                            style={{
                                minHeight: "500px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            >
                            <PulseLoader color="grey" />
                            </div>
                    ) : (
                        <>
                            <DataTable
                                tableClassName="data-table-head-light table-responsive"
                                data={bookdetails}
                        
                                columns={bookingCol}
                                search={"HOTEL_NAME"}
                                setFilteredTable={setBookdetails}
                                originalTable={originalBookdetails}
                            />
                            <div className="_8bfi">
                                <div className="wifely-ski">
                                    <span>Total Bookings:</span> {bookdetails.length}
                                </div>
                                <div className="wifely-ski">
                                    <span>Total Amount:</span> {totalBalance}
                                </div>
                            </div>
                        </>
                    )}
                </Card>
            </Block>
        </Layout>
    )
}

export default BookingsCommissionOwner
